.mntitle {
  text-align: center;
  font-family: 'Cinzel Decorative', cursive;
  font-size: 6rem;
  color: #df8399;
}

.link {
  color: deepskyblue;
  font-size: 3.2rem;
}

.boxdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project {
  text-decoration: none;
  margin-top: 8px;
  color: #028bd4;
  background-color: white;
  border-radius: 10px;
  font-size: 1.2rem;
}

.aboutme {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 9rem;
}

.freizeit {
  font-size: 2rem;
}

.images {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titlesprachen {
  font-size: 2rem;
  color: #028ad4b8;
  margin-bottom: 1px;
  margin-top: 1px;
  font-weight: 900;
}

.titlesprachenn {
  font-size: 2rem;
  color: #028ad4b8;
  margin-bottom: 1px;
  margin-top: 1px;
  font-weight: 900;
}

.bild {
  justify-self: left;
  max-width: 30%;
  height: auto;
  border-radius: 30px;
}

.home {
  margin-top: 3rem;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.ausbildung {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 8rem;
  margin-bottom: 10rem;
  color: #E2C9D1;
}

.bildone {
  max-width: 90%;
  height: auto;
  align-self: center;
  margin-top: 2rem;
  margin-left: 4rem;
}


.jahr {
  flex: none;
  color: #df8399;
  background-color: white;
  font-size: 1.2rem;
  border-radius: 300px;
}

.schule {
  font-size: 1.8rem;
  color: #df8399;
  background-color: white;
  border-radius: 10px;
}

.strich {
  font-size: 2rem;
  color: #df8399;
}

.titel {
  align-self: center;
  /* color: #df8399;*/
  color: #df8399;
  font-size: 4rem;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.titelgit {
  align-self: center;
  color: rgb(250, 154, 170);
  font-size: 4.5rem;
  margin-top: 10rem;
  margin-bottom: none;
}

.hobby {
  color: #df8399;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfoliolink {
  text-decoration: none;
}

.portfoliolink:hover {
  text-decoration: none;
}

.hobbyname {
  border: 2px solid #df8399;
  color: #df8399;
  padding: 7px;
  border-radius: 20px;
  font-size: 1.3rem;
  font-style: bold;
  ;
}

.hobbynamehh {
  border: 2px solid #df8399;
  color: #df8399;
  padding: 8px;
  border-radius: 20px;
  font-size: 1.2rem;
  margin-right: 9rem;
  margin-left: 9rem;
}

.hobbys {
  display: flex;
  flex-direction: row;
}

.titelaboutme {
  align-self: center;
  color: #df8399;
  font-size: 4.5rem;
  margin-top: 0.1rem;
  margin-bottom: 3rem;
}

.sprachen {
  text-decoration: none;
  color: #028bd4;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  padding-left: 5px;
  font-size: 1.3rem;
}

.sprachen2 {
  text-decoration: none;
  color: #028bd4;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  padding-left: 5px;
  font-size: 0.8rem;
}


.sprachen:hover {
  text-decoration: none;
  color: white;
  background-color: rgba(23, 170, 255, 0.575);
  border-radius: 15px;
}

.skillsprojects {
  display: flex;
  flex-direction: column;
}

.titelSkillsprojects {
  align-self: center;
  color: #028ad4b8;
  font-size: 4.5rem;
  margin-top: 1rem;
  margin-bottom: 7rem;
}

.boxes {
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.boxsprachen {
  border: 5px solid rgb(226, 242, 255);
  border-radius: 20px;
  margin-right: 3rem;
}

.linkedin {
  font-size: 7rem;
  margin-right: 6rem;
  color: pink;
  text-decoration: none;

}

.linkedin:hover {
  color: blue;
  text-decoration: none;

}

.boxx {
  border: 2px solid #df8399;
  border-radius: 20px;
  padding: 6px;
  min-width: 22rem;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4rem;
}

.email {
  font-size: 8rem;
  color: pink;
  text-decoration: none;

}

.email:hover {
  color: red;
  text-decoration: none;
}

.bewerbung {
  color: pink;
  font-size: 7rem;
  text-decoration: none;

}

.bewerbung:hover {
  color: yellow;
  text-decoration: none;

}

.intouch {
  display: flex;
  flex-direction: column;
  align-items: center;
}




@media screen and (max-width: 935px) {
  .hobbynamehh {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
  }

  .hobbyname {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .bildone {
    max-width: 90%;
    height: auto;
    align-self: center;
    margin-top: 7rem;
    margin-left: 12px;
  }

  .hobbys {
    flex-direction: column;
  }

  .bild {
    max-width: 50%;
    margin-top: 12rem;
    margin-bottom: 8rem;
    border-radius: 5px;
  }

  .boxx {
    min-width: 10px;
  }

  .home {
    margin-top: 6rem;
  }

  .schule {
    word-wrap: break-word;
    font-size: 1.5rem;
    padding: 0px;
  }

  .mntitle {
    margin-top: 7rem;
  }

  .boxeinsdiv {
    margin-bottom: 3rem;
  }

  .titel {
    font-size: 3.6rem;
    margin-top: 1rem;
  }

  .strich {
    font-size: 1px;
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
  }


  .email {
    margin-right: 1rem;
  }


  .boxdiv {
    display: flex;
    flex-direction: column;
  }

  .home {
    flex-direction: column;
  }


  .mntitle {
    font-size: 4rem;
  }

  .boxes {
    flex-direction: column;
    align-items: center;
  }

  .boxsprachen {
    align-self: center;
    margin-right: 0px;
    margin-bottom: 3rem;
  }

  .sprachen {
    font-size: 1.2rem;
  }

  .titelSkillsprojects {
    font-size: 2.5rem;
    word-wrap: break-word;
  }

  .titelgit {
    font-size: 3rem;
    word-wrap: break-word;
  }

  .linkedin,
  .email,
  .bewerbung {
    font-size: 2.8rem;
  }

  .socials {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 6rem;
    margin-bottom: 12rem;
  }

  .email {
    font-size: 6rem;
    margin-right: 1rem;
  }

  .linkedin {
    font-size: 5rem;
    margin-left: 1rem;
    margin-right: 1.5rem;
  }
}